<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ $t("view.video_url") }}
      </h4>
      <div class="col-12 pd-0" style="padding: 0">
        <input
          id="video_url"
          class="form-control text-lowercase text-left"
          style="text-align: left"
          type="text"
          v-model="row.video_url"
          @keydown.space.prevent
          v-on:input="onInputChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  namme: "CardVideo",
  props: ["video_url"],
  data() {
    return {
      row: {
        video_url: this.$props.video_url,
      },
    };
  },
  methods: {
    onInputChange() {
      const data = {
        video_url: this.row.video_url,
      };
      this.$emit("videoChange", data);
    },

    preventSpace(str) {
      if (!str) {
        let str = this.row.video_url;
        this.row.video_url = str.replace(/\s+/g, "-");
      } else {
        this.row.video_url = str.replace(/\s+/g, "-");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-left {
  text-align: left !important;
}
</style>
